@import '../../scss/utilities';

.trending {
  @include make-row();
  margin-bottom: $grid-gutter-width;
}

.trending__header {
  @include make-col-ready();
  @include make-col(12);
  margin-bottom: $grid-gutter-width;
}

.trending__item {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-only(md) {
    @include make-col(6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
  &_drop {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.trending__rank {
  font-family: $ue-header-font;
  font-weight: 700;
  color: $ue-light-gray;
  font-size: $ue-font-size-3;
  width: $ue-font-size-3;
  padding-top: $spacer*0.5;
  margin-right: $grid-gutter-width*0.5;
  display: flex;
  justify-content: center;
  align-content: center;
}

.trending__article {
  padding: $grid-gutter-width 0;
}
