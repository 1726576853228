@import '../../scss/utilities';

.hero {
  @include media-breakpoint-up(lg) {
    @include make-container();
    @include make-container-max-widths();
  }
}

.hero__wrapper {
  @include make-row();
  margin-left: 0;
  margin-right: 0;
  @include media-breakpoint-up(lg) {
    margin-left: -$grid-gutter-width*0.5;
    margin-right: -$grid-gutter-width*0.5;
    padding-top: $grid-gutter-width * 2;
    padding-bottom: $grid-gutter-width * 2;
  }
}

.hero__main {
  @include make-col-ready();
  @include make-col(12);
  margin-bottom: $grid-gutter-width * 2;
  @include media-breakpoint-up(lg) {
    @include make-col(9);
    margin-bottom: 0;
  }
}

.hero__side {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(lg) {
    @include make-col(3);
    padding-top: $grid-gutter-width;
  }
}

.hero__linkContainer {
  @include make-row();
}

.hero__linkWrapper {
  flex: 0 0 100%;
  padding: 0 $grid-gutter-width*0.5 $grid-gutter-width;
}

.hero__link {
  @include make-col-ready();
  @include make-col(12);
  display: flex;
  margin-bottom: $grid-gutter-width;
  .hero__linkWrapper {
    border-bottom: $ue-dashed-border;
  }
  &:last-child {
    .hero__linkWrapper {
      border-bottom: none;
    }
  }
  @include media-breakpoint-only(sm) {
    padding-left: $grid-gutter-width * 2;
    padding-right: $grid-gutter-width * 2;
  }
  @include media-breakpoint-only(md) {
    @include make-col(6);
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    &:first-child {
      padding-left: $grid-gutter-width * 2;
      padding-right: $grid-gutter-width;
      .hero__linkWrapper {
        border-bottom: $ue-dashed-border;
      }
    }
    &:nth-child(2) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width * 2;
      .hero__linkWrapper {
        border-bottom: $ue-dashed-border;
      }
    }
    &:nth-child(3) {
      padding-left: $grid-gutter-width * 2;
      padding-right: $grid-gutter-width;
      .hero__linkWrapper {
        border-bottom: none;
      }
    }
    &:last-child {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width * 2;
    }
  }
  @include media-breakpoint-up(lg) {
    padding-left: $grid-gutter-width*0.5;
    padding-right: $grid-gutter-width*0.5;
  }
}
