@import '../../scss/utilities';

$headshot-height: 105px;

.spotlight {
  @include media-breakpoint-down(sm) {
    // stylelint-disable-next-line declaration-no-important
    background-image: none !important;
  }
  @include media-breakpoint-only(md) {
    padding: 13.5rem 12rem 0;
  }
  @include media-breakpoint-up(md) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }
  @include media-breakpoint-up(lg) {
    padding: 13.5rem 10.5rem 0;
    box-shadow: 0px 0px 30px $ue-shadow;
  }
}

.spotlight__mobileImage {
  height: 320px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.spotlight__image {
  @each $category, $color in $category-colors {
    &_#{$category}Even {
      background-image: url('/images/categories/#{$category}/hero-#{$category}-even-xs.png');
      // stylelint-disable plugin/no-unsupported-browser-features
      @media only screen and (min-resolution: 200dpi),
        only screen and (min-resolution: 1.25dppx) {
        background-image: url('/images/categories/#{$category}/hero-#{$category}-even-xs-retina.png');
      }
      // stylelint-enable plugin/no-unsupported-browser-features
      @include media-breakpoint-only(sm) {
        background-image: url('/images/categories/#{$category}/hero-#{$category}-even-sm.png');
      }
      @include media-breakpoint-only(md) {
        background-image: url('/images/categories/#{$category}/hero-#{$category}-even-md.png');
      }
      @include media-breakpoint-only(lg) {
        background-image: url('/images/categories/#{$category}/hero-#{$category}-even-lg.png');
      }
      @include media-breakpoint-only(xl) {
        background-image: url('/images/categories/#{$category}/hero-#{$category}-even-xl.png');
      }
    }
    &_#{$category}Odd {
      background-image: url('/images/categories/#{$category}/hero-#{$category}-odd-xs.png');
      // stylelint-disable plugin/no-unsupported-browser-features
      @media only screen and (min-resolution: 200dpi),
        only screen and (min-resolution: 1.25dppx) {
        background-image: url('/images/categories/#{$category}/hero-#{$category}-odd-xs-retina.png');
      }
      // stylelint-enable plugin/no-unsupported-browser-features
      @include media-breakpoint-only(sm) {
        background-image: url('/images/categories/#{$category}/hero-#{$category}-odd-sm.png');
      }
      @include media-breakpoint-only(md) {
        background-image: url('/images/categories/#{$category}/hero-#{$category}-odd-md.png');
      }
      @include media-breakpoint-only(lg) {
        background-image: url('/images/categories/#{$category}/hero-#{$category}-odd-lg.png');
      }
      @include media-breakpoint-only(xl) {
        background-image: url('/images/categories/#{$category}/hero-#{$category}-odd-xl.png');
      }
    }
  }
}

.spotlight__contentWrapper {
  @include media-breakpoint-up(md) {
    min-height: 410px;
  }
}

.spotlight__content {
  margin-top: -$headshot-height*0.5;
  @include media-breakpoint-up(md) {
    margin-top: 0;
    padding: $headshot-height*0.5 0;
    position: relative;
    background-color: $ue-white;
  }
}

.spotlight__headshotContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @include media-breakpoint-up(md) {
    position: absolute;
    top: -$headshot-height*0.5;
    right: 0;
    left: 0;
  }
}

.spotlight__headshot {
  border: 5px solid $ue-white;
  border-radius: 50%;
  max-width: $headshot-height;
}

.spotlight__feature {
  text-align: center;
  margin-bottom: $grid-gutter-width;
}

.spotlight__featureName {
  margin: 0;
  text-align: center;
  font-size: $ue-font-size-8;
  font-weight: 700;
  @each $category, $color in $category-colors {
    &_#{$category} {
      // stylelint-disable-next-line declaration-no-important
      color: $color !important;
    }
  }
}

.spotlight__link {
  display: block;
  margin: 0 $grid-gutter-width;
  color: $ue-black;
  &:hover,
  &:focus {
    color: $ue-black;
  }
  @include media-breakpoint-only(sm) {
    margin: 0 7.5rem;
  }
  @include media-breakpoint-up(md) {
    margin: 0 $grid-gutter-width * 3;
  }
  @include media-breakpoint-only(lg) {
    margin: 0 4.5rem;
  }
  @each $category, $color in $category-colors {
    &_#{$category} {
      // stylelint-disable-next-line declaration-no-important, plugin/no-unsupported-browser-features
      text-decoration-color: $color !important;
    }
  }
}

.spotlight__headline {
  margin: 0;
  font-family: $ue-header-font;
  font-weight: 700;
  font-size: $ue-font-size-5;
  line-height: 1.2;
  @include media-breakpoint-up(md) {
    font-size: $ue-font-size-3;
  }
}
