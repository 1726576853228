@import '../../../scss/utilities';

.article {
  &:focus,
  &:hover {
    color: $ue-black;
  }
  @each $category, $color in $category-colors {
    &--#{$category} {
      // stylelint-disable-next-line declaration-no-important, plugin/no-unsupported-browser-features
      text-decoration-color: $color !important;
    }
  }
}

.article__column {
  margin-bottom: 0.4rem;
  text-transform: uppercase;
  font-family: $ue-subheader-font;
  font-weight: 700;
  font-size: $ue-font-size-11;
  color: $ue-black;
  @each $category, $color in $category-colors {
    &--#{$category} {
      // stylelint-disable-next-line declaration-no-important
      color: $color !important;
    }
  }
}

.article__title {
  margin-bottom: 0;
  font-family: $ue-subheader-font;
  font-weight: 300;
  font-size: $ue-font-size-9;
  color: $ue-black;
}
